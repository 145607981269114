exports.shouldUpdateScroll = () => {
  setTimeout(() => {
    // Check if window is defined (so if in the browser or in node.js).
    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {
      window.scrollTo(0, 0);
    }
  }, 100);
  return [0, 0];
};

exports.onServiceWorkerUpdateReady = () => {
  const isBrowser = typeof window !== `undefined`
  if (isBrowser) {
    window.location.reload();
  }
};
