exports.components = {
  "component---src-modules-agency-templates-agency-tsx": () => import("./../../../src/modules/agency/templates/agency.tsx" /* webpackChunkName: "component---src-modules-agency-templates-agency-tsx" */),
  "component---src-modules-contact-templates-contact-tsx": () => import("./../../../src/modules/contact/templates/contact.tsx" /* webpackChunkName: "component---src-modules-contact-templates-contact-tsx" */),
  "component---src-modules-culture-templates-culture-tsx": () => import("./../../../src/modules/culture/templates/culture.tsx" /* webpackChunkName: "component---src-modules-culture-templates-culture-tsx" */),
  "component---src-modules-culture-templates-posts-desarrollo-web-astro-strapi-tsx": () => import("./../../../src/modules/culture/templates/posts/desarrollo-web-astro-strapi.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-desarrollo-web-astro-strapi-tsx" */),
  "component---src-modules-culture-templates-posts-estado-javascript-2021-tsx": () => import("./../../../src/modules/culture/templates/posts/estado-javascript-2021.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-estado-javascript-2021-tsx" */),
  "component---src-modules-culture-templates-posts-figma-y-workoholics-tsx": () => import("./../../../src/modules/culture/templates/posts/figma-y-workoholics.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-figma-y-workoholics-tsx" */),
  "component---src-modules-culture-templates-posts-friccion-productos-digitales-tsx": () => import("./../../../src/modules/culture/templates/posts/friccion-productos-digitales.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-friccion-productos-digitales-tsx" */),
  "component---src-modules-culture-templates-posts-las-putas-amas-tsx": () => import("./../../../src/modules/culture/templates/posts/las-putas-amas.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-las-putas-amas-tsx" */),
  "component---src-modules-culture-templates-posts-laus-2024-tsx": () => import("./../../../src/modules/culture/templates/posts/laus-2024.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-laus-2024-tsx" */),
  "component---src-modules-culture-templates-posts-naming-tsx": () => import("./../../../src/modules/culture/templates/posts/naming.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-naming-tsx" */),
  "component---src-modules-culture-templates-posts-navidad-2023-tsx": () => import("./../../../src/modules/culture/templates/posts/navidad2023.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-navidad-2023-tsx" */),
  "component---src-modules-culture-templates-posts-todo-va-a-salir-bien-tsx": () => import("./../../../src/modules/culture/templates/posts/todo-va-a-salir-bien.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-todo-va-a-salir-bien-tsx" */),
  "component---src-modules-culture-templates-posts-wip-2022-tsx": () => import("./../../../src/modules/culture/templates/posts/wip2022.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-wip-2022-tsx" */),
  "component---src-modules-culture-templates-posts-wip-2023-js": () => import("./../../../src/modules/culture/templates/posts/wip2023.js" /* webpackChunkName: "component---src-modules-culture-templates-posts-wip-2023-js" */),
  "component---src-modules-culture-templates-posts-work-sweet-work-tsx": () => import("./../../../src/modules/culture/templates/posts/work-sweet-work.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-work-sweet-work-tsx" */),
  "component---src-modules-culture-templates-posts-worko-party-tsx": () => import("./../../../src/modules/culture/templates/posts/worko-party.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-worko-party-tsx" */),
  "component---src-modules-culture-templates-posts-workoholics-x-irontec-tsx": () => import("./../../../src/modules/culture/templates/posts/workoholics-x-irontec.tsx" /* webpackChunkName: "component---src-modules-culture-templates-posts-workoholics-x-irontec-tsx" */),
  "component---src-modules-home-templates-home-tsx": () => import("./../../../src/modules/home/templates/home.tsx" /* webpackChunkName: "component---src-modules-home-templates-home-tsx" */),
  "component---src-modules-landings-kit-digital-templates-kit-digital-js": () => import("./../../../src/modules/landings/kit-digital/templates/kit-digital.js" /* webpackChunkName: "component---src-modules-landings-kit-digital-templates-kit-digital-js" */),
  "component---src-modules-landings-kit-digital-templates-kit-digital-success-js": () => import("./../../../src/modules/landings/kit-digital/templates/kit-digital-success.js" /* webpackChunkName: "component---src-modules-landings-kit-digital-templates-kit-digital-success-js" */),
  "component---src-modules-legal-templates-legal-js": () => import("./../../../src/modules/legal/templates/legal.js" /* webpackChunkName: "component---src-modules-legal-templates-legal-js" */),
  "component---src-modules-services-templates-services-tsx": () => import("./../../../src/modules/services/templates/services.tsx" /* webpackChunkName: "component---src-modules-services-templates-services-tsx" */),
  "component---src-modules-team-templates-team-js": () => import("./../../../src/modules/team/templates/team.js" /* webpackChunkName: "component---src-modules-team-templates-team-js" */),
  "component---src-modules-work-in-progress-work-in-progress-2022-templates-work-in-progress-js": () => import("./../../../src/modules/work-in-progress/work-in-progress-2022/templates/work-in-progress.js" /* webpackChunkName: "component---src-modules-work-in-progress-work-in-progress-2022-templates-work-in-progress-js" */),
  "component---src-modules-work-in-progress-work-in-progress-2022-templates-work-in-progress-speaker-js": () => import("./../../../src/modules/work-in-progress/work-in-progress-2022/templates/work-in-progress-speaker.js" /* webpackChunkName: "component---src-modules-work-in-progress-work-in-progress-2022-templates-work-in-progress-speaker-js" */),
  "component---src-modules-work-in-progress-work-in-progress-2023-templates-work-in-progress-js": () => import("./../../../src/modules/work-in-progress/work-in-progress-2023/templates/work-in-progress.js" /* webpackChunkName: "component---src-modules-work-in-progress-work-in-progress-2023-templates-work-in-progress-js" */),
  "component---src-modules-work-in-progress-work-in-progress-2023-templates-work-in-progress-speaker-js": () => import("./../../../src/modules/work-in-progress/work-in-progress-2023/templates/work-in-progress-speaker.js" /* webpackChunkName: "component---src-modules-work-in-progress-work-in-progress-2023-templates-work-in-progress-speaker-js" */),
  "component---src-modules-work-in-progress-work-in-progress-2024-templates-work-in-progress-js": () => import("./../../../src/modules/work-in-progress/work-in-progress-2024/templates/work-in-progress.js" /* webpackChunkName: "component---src-modules-work-in-progress-work-in-progress-2024-templates-work-in-progress-js" */),
  "component---src-modules-work-in-progress-work-in-progress-2024-templates-work-in-progress-speaker-js": () => import("./../../../src/modules/work-in-progress/work-in-progress-2024/templates/work-in-progress-speaker.js" /* webpackChunkName: "component---src-modules-work-in-progress-work-in-progress-2024-templates-work-in-progress-speaker-js" */),
  "component---src-modules-work-templates-case-studies-abus-tsx": () => import("./../../../src/modules/work/templates/case-studies/abus.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-abus-tsx" */),
  "component---src-modules-work-templates-case-studies-athletic-club-tsx": () => import("./../../../src/modules/work/templates/case-studies/athletic-club.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-athletic-club-tsx" */),
  "component---src-modules-work-templates-case-studies-azti-tsx": () => import("./../../../src/modules/work/templates/case-studies/azti.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-azti-tsx" */),
  "component---src-modules-work-templates-case-studies-bbk-kuna-tsx": () => import("./../../../src/modules/work/templates/case-studies/bbk-kuna.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-bbk-kuna-tsx" */),
  "component---src-modules-work-templates-case-studies-bellota-tsx": () => import("./../../../src/modules/work/templates/case-studies/bellota.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-bellota-tsx" */),
  "component---src-modules-work-templates-case-studies-biscaytik-tsx": () => import("./../../../src/modules/work/templates/case-studies/biscaytik.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-biscaytik-tsx" */),
  "component---src-modules-work-templates-case-studies-btem-tsx": () => import("./../../../src/modules/work/templates/case-studies/btem.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-btem-tsx" */),
  "component---src-modules-work-templates-case-studies-cafe-fortaleza-tsx": () => import("./../../../src/modules/work/templates/case-studies/cafe-fortaleza.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-cafe-fortaleza-tsx" */),
  "component---src-modules-work-templates-case-studies-cronoland-tsx": () => import("./../../../src/modules/work/templates/case-studies/cronoland.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-cronoland-tsx" */),
  "component---src-modules-work-templates-case-studies-eroski-corporativo-tsx": () => import("./../../../src/modules/work/templates/case-studies/eroski-corporativo.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-eroski-corporativo-tsx" */),
  "component---src-modules-work-templates-case-studies-euskaltel-tsx": () => import("./../../../src/modules/work/templates/case-studies/euskaltel.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-euskaltel-tsx" */),
  "component---src-modules-work-templates-case-studies-getxo-kultura-tsx": () => import("./../../../src/modules/work/templates/case-studies/getxo-kultura.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-getxo-kultura-tsx" */),
  "component---src-modules-work-templates-case-studies-gizakia-tsx": () => import("./../../../src/modules/work/templates/case-studies/gizakia.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-gizakia-tsx" */),
  "component---src-modules-work-templates-case-studies-iberdrola-tsx": () => import("./../../../src/modules/work/templates/case-studies/iberdrola.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-iberdrola-tsx" */),
  "component---src-modules-work-templates-case-studies-kirolapp-tsx": () => import("./../../../src/modules/work/templates/case-studies/kirolapp.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-kirolapp-tsx" */),
  "component---src-modules-work-templates-case-studies-lasai-tsx": () => import("./../../../src/modules/work/templates/case-studies/lasai.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-lasai-tsx" */),
  "component---src-modules-work-templates-case-studies-last-tour-tsx": () => import("./../../../src/modules/work/templates/case-studies/last-tour.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-last-tour-tsx" */),
  "component---src-modules-work-templates-case-studies-ubikare-tsx": () => import("./../../../src/modules/work/templates/case-studies/ubikare.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-ubikare-tsx" */),
  "component---src-modules-work-templates-case-studies-virgin-tsx": () => import("./../../../src/modules/work/templates/case-studies/virgin.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-virgin-tsx" */),
  "component---src-modules-work-templates-case-studies-visor-urdaibai-tsx": () => import("./../../../src/modules/work/templates/case-studies/visor-urdaibai.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-visor-urdaibai-tsx" */),
  "component---src-modules-work-templates-case-studies-zeid-tsx": () => import("./../../../src/modules/work/templates/case-studies/zeid.tsx" /* webpackChunkName: "component---src-modules-work-templates-case-studies-zeid-tsx" */),
  "component---src-modules-work-templates-work-tsx": () => import("./../../../src/modules/work/templates/work.tsx" /* webpackChunkName: "component---src-modules-work-templates-work-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

